import Modal from '@components/misc/WelcomeModal2.vue'
import Button from '@components/misc/StartModalButton.vue'
import { generateApplication } from '@helpers/generate_component.js';
import { autoAnimatePlugin } from "@formkit/auto-animate/vue"


import { createPinia } from 'pinia'


const pinia = createPinia()

generateApplication(Modal, "#welcome", {
  packages: [autoAnimatePlugin, pinia],
})

generateApplication(Button, "#StartButton", {
  packages: [autoAnimatePlugin, pinia],
})

// generateApplication(App, "#welcome")
